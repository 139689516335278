import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTableViewOption from 'common/models/enums/models/ModelTableViewOption';

export default class TypeTableViewOptionEnum extends AbstractEnumType {
  public static readonly OMNI = new ModelTableViewOption(
    'omni',
    'omni',
    null,
    2,
    true,
  );

  public static readonly IN_STORE = new ModelTableViewOption(
    'inStore',
    'in',
    'in_store',
    3,
    true,
  );

  public static readonly OUT_STORE = new ModelTableViewOption(
    'outStore',
    'ecommerce',
    'out_store',
    4,
    true,
  );

  public static readonly ALL: ModelTableViewOption[] = this.all(
    TypeTableViewOptionEnum,
    ModelTableViewOption,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelTableViewOption,
  ): ModelTableViewOption {
    return this.by(
      this,
      ModelTableViewOption,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
