import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isEqual } from 'lodash';

import {
  I18n,
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import dayjs from 'dayjs';

import store from 'common/store';

import Authentication from 'core/Authentication';
import Initialization from 'core/Initialization';
import Languages from 'common/i18n/languages';
import NavigatorUtils from 'common/utils/navigator';
import Maintenance from 'core/router/maintenance';

import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);

dayjs.updateLocale(NavigatorUtils.getLanguage(), {
  weekStart: 7,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useEffect(() => {
    const initializeTranslations = () => {
      const handleMissingTranslation = (key) => `Missing translation: ${key}`;
      I18n.setHandleMissingTranslation(handleMissingTranslation);

      syncTranslationWithStore(store);

      loadTranslations(Languages)(
        store.dispatch.bind(store),
        store.getState.bind(store),
      );
      setLocale(NavigatorUtils.getLanguage())(
        store.dispatch.bind(store),
        store.getState.bind(store),
      );
    };

    initializeTranslations();
  }, []);

  const isMaintenanceModeActive = isEqual(
    import.meta.env.VITE_MAINTENANCE_MODE,
    'on',
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {isMaintenanceModeActive ? (
          <Maintenance />
        ) : (
          <BrowserRouter>
            <Authentication>
              <Initialization />
            </Authentication>
          </BrowserRouter>
        )}
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
